import { BuilderService } from "~/services/builder.service";
import { EcomService } from "~/services/ecom.service";
import type { APIState } from "~/types/api-state.type";
import type { Brands } from "~/types/builder-brands.type";

export const useBrandStore = defineStore('brand', {
    state: () => ({
        builder: null as BuilderService | null,
        builder_api_key: useRuntimeConfig().public.builderApiKey,
        activeComponent: null,
        layout: 'brand-plp',
        brandSlug: null,
        activeTab: 'about',
        brands: <Brands>{},
        all_brands: {},
        specialBrand: <boolean>false,
        brandName: <string>'',
        builderBrandPage: <any>{}
    }),
    actions: {
        async getBrands() {
            if (!this.builder) {
                this.builder = new BuilderService(this.builder_api_key);
            }
            this.brands = await this.builder.getContentModel('brands', this.builder_api_key);
        },

        async getAllBrands() {
            try {
                const response = await EcomService.getAllBrandsData();
                this.all_brands = response.data;
            } catch (err) {
                await useErrorHandler(err);
            }
        },

        async handleBrandCardClick(brandName: string) {
            const algoliaStore = useAlgoliaStore();
            algoliaStore.lastSearchedKeyword = brandName;
            algoliaStore.currentActiveBrand = brandName;

            const builderStore = useBuilderStore();

            if (builderStore.topBrands.length > 0) {
                builderStore.fetchTopBrands();
            }

            const len = builderStore.topBrands.length;
            const brandFacetFilter = [[`brand:${algoliaStore.currentActiveBrand}`]];

            for (let index = 0; index < len; index++) {
                const brand = builderStore.topBrands[index];
                // Check if redirectToBrandListing is true for the brand
                if (brandName.toLowerCase() === brand.title.toLowerCase()) {

                    this.specialBrand = brand.redirectToBrandListing ?? false;
                    break;
                } else {
                    this.specialBrand = false;
                }
            }
            algoliaStore.handleEnterClick(algoliaStore.currentActiveBrand, "brand-search", true, brandFacetFilter);
        },

        async brandPageInit() {

            /**
             * This method is called to initialise the brand page and layout with the right component. 
             */

            const route = useRoute();
            const algoliaStore = useAlgoliaStore();
            const builderStore = useBuilderStore();
            const searchResultStore = useSearchResultStore();

            searchResultStore.selectedFilterState =
                algoliaStore.extractFiltersFromURL(route.fullPath);


            if (!algoliaStore.currentActiveBrand) {
                algoliaStore.currentActiveBrand = route.params.brandname;
            }

            if (!algoliaStore.lastSearchedKeyword) {
                algoliaStore.lastSearchedKeyword = algoliaStore.currentActiveBrand;
            }




            if (builderStore.topBrands.length < 0) {
                await builderStore.fetchTopBrands();
            }

            const len = builderStore.topBrands.length;

            for (let index = 0; index < len; index++) {
                const brand = builderStore.topBrands[index];
                // Check if redirectToBrandListing is true for the brand
                if (algoliaStore.currentActiveBrand.toLowerCase() === brand.title.toLowerCase()) {

                    this.specialBrand = brand.redirectToBrandListing ?? false;
                    break;
                } else {
                    this.specialBrand = false;
                }
            }
            algoliaStore.handleEnterClick(algoliaStore.currentActiveBrand, "brand-search", true, searchResultStore.transformSelectedFilters());
        }
    },

})